import styled from 'styled-components'
// import Dialog from '@material-ui/core/Dialog'

export const AppBlock = styled('div')`
  .addBullet {
    margin-left: 9px;
    display: list-item;
  }
  .colorRed {
    color: red;
  }
  .MuiFormHelperText-root.Mui-error {
    font-size: small;
  }
  div.allKanit {
    * {
      font-family: "Kanit", sans-serif !important;
    }
  }
  div.allHelvetica,
  p.allHelvetica
  {
    * {
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    }
  }
  .facebookLoginBtn {
    height: 50px;
    margin-bottom: 8px;
  }
  .link {
    color: #9c27b0;
  }
  .unsetTrans {
    transition: unset;
  }
  a.white {
    color: white;
  }
  .errorMessage {
    color: red;
  }
  .MuiListItem-gutters {
    padding: 0;
    .item {
      padding: 9px 3px;
    }
  }
  .topLogo {
    background-repeat: no-repeat;
    background-position: left;
    width: 150px;
    height: 40px;
    background-size: 117px;
  }
  .displayNone {
    display: none;
  }
  .larger {
    font-size: 2em;
  }
  .titleStatus-CLOSED {
    color: red !important;
  }
`
