import "firebase/analytics";

import * as firebase from "firebase/app";
import { useEffect } from 'react'
import { useHistory } from "react-router-dom";

import config from '../firebase-config.json'

firebase.initializeApp(config)

const logCurrentPage = () => {
  firebase.analytics().setCurrentScreen(window.location.pathname)
  firebase.analytics().logEvent('screen_view')
};
const Analytics = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    logCurrentPage();
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);
  return children
}
export default Analytics
