// import "./App.less";
// import routes from "./Routes";
// import FourOhFour from "./404";
import "@app/client/assets/index.css";
import "@app/client/assets/scss/material-kit-pro-react.scss?v=1.8.0";

// import "@app/client/assets/scss/material-kit-pro-react.scss?v=1.8.0";
// import { Route, Switch } from 'react-router'
import { ApolloProvider } from "@apollo/react-hooks";
import { useMeta, useTitle } from "hooked-head";
import NProgress from "nprogress";
import React, { lazy, Suspense } from "react";
// import { NotFoundBoundary, Router, useLoadingRoute, View } from "react-navi";
import { HelmetProvider } from "react-navi-helmet-async";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import client from "./helpers/apolloClient";
import { Reset } from "./routes/Reset";
// import { Login } from "./routes/Login";
import Emails from "./routes/settings/Emails";
import Settings from "./routes/settings/Index";
// import { Verify } from "./routes/Verify";
import { AppBlock } from "./styles.js";
import Analytics from "./utils/Analytics";
// const Emails = lazy(() => import("./routes/Emails"));
// const Settings = lazy(() => import("./routes/Settings/Index"));
// const Login = lazy(() => import("./routes/Login"));
const Forgot = lazy(() => import("./routes/Forgot"));
// const Register = lazy(() => import("./routes/Register"));
// const Home = lazy(() => import("./routes/Home"));
// const HomePage = lazy(() => import("./views/HomePage/HomePage"));
const Verify = lazy(() => import("./views/VerifyPage/VerifyPage"));
const LoginPage = lazy(() => import("./views/LoginPage/LoginPage"));
const RegisterPage = lazy(() => import("./views/RegisterPage/RegisterPage"));
const ProjectsPage = lazy(() => import("./views/ProjectsPage/ProjectsPage"));
const ProjectPage = lazy(() => import("./views/ProjectPage/ProjectPage"));
const AgreementPage = lazy(() => import("./views/AgreementPage/AgreementPage"));
const CheckoutPage = lazy(() => import("./views/CheckoutPage/CheckoutPage"));
const ContactUsPage = lazy(() => import("./views/ContactUsPage/ContactUsPage"));
const ErrorPage = lazy(() => import("./views/ErrorPage/ErrorPage"));
const CreateProjectPage = lazy(() =>
  import("./views/CreateProjectPage/CreateProjectPage")
);
const HowItWorks = lazy(() => import("./views/HowItWorksPage/HowItWorksPage"));
// const AboutUsPage = lazy(() => import("./views/AboutUsPage/AboutUsPage"));
const AboutUsPage = HowItWorks;
const PrivacyPolicy = lazy(() =>
  import("./views/PrivacyPolicyPage/PrivacyPolicyPage")
);
const TermsOfUse = lazy(() => import("./views/TermsOfUsePage/TermsOfUsePage"));
const ContributionPage = lazy(() =>
  import("./views/ContributionPage/ContributionPage")
);
const IndividualPage = lazy(() =>
  import("./views/IndividualPage/IndividualPage")
);
// require("@app/client/assets/scss/material-kit-pro-react.scss?v=2.0.0");
// const Settings = lazy(() => import("./routes/settings/Index"));
// const Emails = lazy(() => import("./routes/settings/Emails"));
// const HomePage = import("./routes/Home")

NProgress.configure({
  showSpinner: false,
});

const App2: React.FC = () => {
  // TODO: put values in main config
  useTitle("XUXU สู้สู้ แพลตฟอร์ม Crowdfunding");
  useMeta({
    name: "description",
    content:
      "XUXU สู้สู้ แพลตฟอร์ม Crowdfunding ของไทย ชอบเหมือนกัน สู้สู้ ไปด้วยกัน พื้นที่สำหรับไอเดียเจ๋งๆ ที่ต้องการการสนับสนุน มาร่วมสร้างสรรค์โปรเจกต์ หรือร่วมสนับสนุนเพื่อสร้างโปรเจกต์ไปด้วยกัน",
  });
  useMeta({
    name: "keywords",
    content: "แพลตฟอร์มระดมทุน ,Crowdfunding, เว็บระดมทุน , XUXU, สู้ๆ, สู้สู้",
  });
  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Analytics>
            <AppBlock>
              <Suspense fallback={null}>
                <Switch>
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/register" component={RegisterPage} />
                  <Route exact path="/register2" component={RegisterPage} />
                  <Route exact path="/Forgot" component={Forgot} />
                  <Route exact path="/Reset" component={Reset} />
                  <Route exact path="/settings" component={Settings} />
                  <Route exact path="/settings/emails" component={Emails} />
                  <Route exact path="/how-it-works" component={HowItWorks} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  <Route exact path="/terms-of-use" component={TermsOfUse} />
                  <Route exact path="/about-us" component={AboutUsPage} />
                  <Route exact path="/contact-us" component={ContactUsPage} />
                  <Route exact path="/verify" component={Verify} />
                  <Route
                    exact
                    path="/create-project"
                    component={CreateProjectPage}
                  />
                  {/* <Route exact path="/projects/:id/" component={ProjectPage} /> */}
                  <Route
                    exact
                    path="/projects/:slug/"
                    component={ProjectPage}
                  />
                  <Route
                    exact
                    path="/projects/:slug/agreement"
                    component={AgreementPage}
                  />
                  <Route
                    exact
                    path="/projects/:id/:tab"
                    component={ProjectPage}
                  />
                  <Route path="/individual/:id" component={IndividualPage} />
                  <Route
                    exact
                    path="/contribution:pay/:id"
                    component={CheckoutPage}
                  />
                  <Route
                    exact
                    path="/contribution/:id"
                    component={ContributionPage}
                  />
                  <Route exact path="/" component={ProjectsPage} />
                  <Route path="/404" component={ErrorPage} />
                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </AppBlock>
          </Analytics>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
};
// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <HelmetProvider>
//         <ApolloProvider client={client}>
//           <Router routes={routes}>
//             <Loading />
//             <NotFoundBoundary render={FourOhFour}>
//               <Suspense fallback={null}>
//                 <View />
//               </Suspense>
//             </NotFoundBoundary>
//           </Router>
//         </ApolloProvider>
//       </HelmetProvider>
//     </div>
//   );
// };

export default App2;
